.questionsCont,
.question,
.answersCont {
    display: flex;
    flex-direction: column;
}

.questionsCont {
    row-gap: 20px;
}

.question {
    row-gap: 10px;
}

.answersCont {
    row-gap: 4px;
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray);
}

.optionCont {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

@media (hover: hover) {
    .answer:hover {
        background-color: var(--secondary-light-gray);
    }
    .answer.active:hover {
        background-color: var(--text-light-green);
    }
    .answer.correct:hover {
        background-color: var(--primary-green);
    }
}
