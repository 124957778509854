.textDisplay {
    padding: 12px 14px;
    padding-top: 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    width: 100%;
    height: 100%;
    max-height: 15%;
    background: var(--modal-overlay-background);
}

.currentSentence {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4.6px;
    margin: 0;
    padding: 3px;
    position: relative;
    align-items: flex-start;
    line-height: 1.3;
    cursor: pointer;
}

.word {
    color: var(--transparent-gray);
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.word.active {
    color: var(--white);
}
