.contentModal {
    width: 40vw;
    min-width: 40vw;
    width: fit-content;
    max-width: 650px;
    padding: 20px;
    border-radius: 24px;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin: 0 auto;
}

.modalTitle {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 22px;
}

.subtitle {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
}

.subtitle:nth-child(3) {
    margin-top: -12px;
    margin-bottom: 12px;
}

.modalTitle svg {
    height: 30px;
    min-height: 30px;
    width: 30px;
}

.buttonsContainer {
    display: flex;
    background: var(--white);
    padding: 6px;
    gap: 12px;
}

.buttonsContainer > button {
    width: 33%;
}

.intro {
    padding: 20px;
    max-width: 640px;
    opacity: 0;
    transition: opacity 0.5s;
    animation: fadeIn 0.2s ease-in-out forwards;
    user-select: none;
    z-index: 12;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}

.subtitle {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    margin-top: 12px;
    white-space: pre-line;
    text-align: justify;
}

.intro > button {
    width: 100%;
    margin-top: 24px;
    font-size: 15px;
    line-height: 18px;
    height: 38px;
    min-height: 38px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
