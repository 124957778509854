.exerciseOptions {
    display: flex;
    width: 100%;
    margin: auto;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    overflow: scroll;
    position: relative;
}

.inputsContainer {
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.optionsContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.optionsContainer:nth-child(1) {
    align-items: center;
    justify-content: space-between;
}

.optionsContainer:nth-child(4) {
    justify-content: space-between;
}

.errorMessage {
    line-height: 32px;
    font-size: 12px;
    color: var(--primary-red);
}

.preview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid var(--primary-light-gray);
    padding: 6px 12px;
    border-radius: 12px;
}

.preview p {
    left: 0;
}
