.alert {
    bottom: 72px;
    left: 32px;
    position: absolute;
    width: calc(38% - 40px);
    max-width: calc(38% - 40px);
    padding: 6px 12px;
    border-radius: 8px;
    background: var(--orange);
    box-shadow: 0px -3px 8px 0px var(--black-transparent);
}

.alert p {
    color: var(--white);
    font-size: 12px;
}
