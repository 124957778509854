.closeButton {
    position: absolute;
    content: url('../../../assets/svg/icon-close.svg');
    top: 20px;
    right: 20px;
    opacity: 0.9;
    cursor: pointer;
    width: 15px;
    height: 15px;
    filter: brightness(1);
}

.closeButton.thin {
    content: url('../../../assets/svg/icon-close_thin.svg');
}

.closeButton:hover {
    filter: brightness(1.5);
}
