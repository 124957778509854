.lettersCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    height: 100%;
}

.answerCont,
.lettersCont {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px;
    padding: 20px;
    transition: opacity 0.8s;
}

.letter {
    border-radius: 6px;
    padding: 5px 8px 9px 8px;
    height: 36px;
    min-width: 36px;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: lowercase;
    color: var(--black);
    cursor: pointer;
    user-select: none;
    background: var(--transparent-gray);
    border: 0.5px solid var(--transparent-gray);
}

.lettersCont .letter {
    background: var(--night-gray);
    color: var(--white);
}

.answerCont.correct .letter {
    border: 0.5px solid var(--green);
    background: var(--green-transparent);
}

.answerCont.wrong .letter {
    background: var(--primary-transparent-red);
    border: 0.5px solid var(--primary-red);
}

.translationText,
.infoText {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
}

.translationText {
    color: var(--text-light-gray-secondary);
    transition: filter 0.2s;
    user-select: none;
    margin-bottom: 8px;
    text-align: center;
}

@media (hover: hover) {
    .lettersCont .letter:hover {
        box-shadow: 0px 77px 46px 0px var(--black);
    }
}
