.actionButton {
    position: absolute;
    right: 12px;
    top: 20px;
    display: flex;
    gap: 3px;
    padding: 4px;
    cursor: pointer;
    background: var(--white);
    border-radius: 8px;
}

.actionButton span {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--text-light-gray);
    transition: background-color 0.2s;
}

.studentCardModal {
    display: flex;
    max-width: 620px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
}

.horizontalCont {
    display: flex;
    gap: 12px;
}

.horizontalCont > div {
    width: 50%;
}

.studentCardModal > * {
    width: 100%;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 32px;
}

.plug {
    min-height: 32px;
}

@media (hover: hover) {
    .actionButton:hover span {
        background: var(--text-light-gray-secondary);
    }
}
