.createExercise {
    width: max-content;
    min-width: 600px;
    max-width: 600px;
    max-height: 90vh;
    overflow: scroll;
    padding: 24px;
}

.createExercise::-webkit-scrollbar {
    width: 0;
}

.previewModal {
    width: 700px;
    max-width: 700px;
    height: 60vh;
    max-height: 60vh;
    padding-bottom: 0;
    display: flex;
}

.modalInner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    overflow: visible scroll;
    flex: 1 1;
}

.createExercise.large {
    max-width: 90vw;
    padding: 0 24px;
    height: 90vh;
    overflow: hidden;
}

.exercisesContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: calc(100% + 20px);
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 30px 20px 0;
    overflow: hidden scroll;
}

.exercisesContainer::-webkit-scrollbar {
    width: 0;
}

.stepTitle,
.stepTitle span {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
}

.stepTitle {
    margin-top: -10px;
    height: 40px;
    padding: 10px 0;
}

.stepTitle span {
    color: var(--text-light-gray);
    margin-left: 12px;
}

.textBlock {
    display: flex;
    flex-direction: column;
}

.titleCont {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.titleCont p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.textBlock > p {
    font-size: 36px;
    font-weight: 400;
    line-height: 49.03px;
    text-align: center;
}

.textBlock span,
.noPreviewAlert {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    text-align: center;
}

.noPreviewAlert {
    margin: auto;
}

.typesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px 24px;
}

.exerciseTypeCard {
    flex: 1;
    height: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 1px 4px 0px var(--black-transparent);
    padding: 8px 12px;
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
}

.exerciseTypeCard.disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: not-allowed;
}

.typesContainer:last-child {
    margin-bottom: 100px;
}

.exerciseTypeCard svg {
    min-width: 30px;
    min-height: 20px;
}

.infoText {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    margin-bottom: 12px;
}

.exerciseTypeCard .modalIcon {
    min-width: 40px;
    position: static;
    transform: none;
}

.exerciseTypeCard .modalIcon path {
    fill: var(--blue);
}

.exerciseTypeCard .helpButton {
    min-height: 40px;
    min-width: 40px;
    position: absolute;
    padding: 10px;
    left: 0;
    top: 0;
}

.helpButton * {
    transition: all 0.2s;
}

@media (hover: hover) {
    .exerciseTypeCard:not(.disabled):hover {
        transform: scale(1.02);
    }
    .helpButton:hover path {
        fill: var(--dark-gray);
        fill-opacity: 1;
    }
    .helpButton:hover rect {
        stroke: var(--dark-gray);
        stroke-opacity: 1;
    }
}
