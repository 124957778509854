.kanban {
    margin-left: -20px;
    margin-right: -20px;
    display: inline-flex;
    height: 100%;
    padding: 20px;
    padding-top: 6px;
    margin-top: 10px;
    overflow: scroll hidden;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
