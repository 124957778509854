.questions {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.question,
.questionGrey {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray);
}

.questionGrey {
    color: var(--text-light-gray-secondary);
}

.studentText {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 10px;
}
