.inputCont:hover .deleteIcon {
    visibility: visible;
    opacity: 100;
}

.deleteIcon {
    opacity: 0;
    transition: opacity 0.2s;
}

.deleteIcon.focus {
    opacity: 1;
}

.adjustableInput {
    width: 100%;
    font-weight: 400;
    padding: 6px 32px 8px 8px;
    margin: 0;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    box-sizing: border-box;
    min-height: 79px;
    line-height: 23px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    outline: none;
    overflow-y: hidden;
    height: max-content;
    transition: background-color 0.1s;
    resize: none;
}

.adjustableInput.sentence {
    padding-bottom: 8px;
}

.adjustableInput.sentence.blur {
    background-color: var(--primary-light-gray);
    border: 1px solid var(--primary-light-gray);
    color: var(--black);
    transition: all 0.2s;
    cursor: pointer;
}

.adjustableInput::-webkit-scrollbar {
    width: 0;
}
