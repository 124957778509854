.sectionButton {
    background: var(--white);
    padding: 10px 12px;
    border: 1px solid var(--text-disabled);
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.sectionButton.pressed:not([disabled]) {
    background: var(--white-pressed);
}

.sectionButton::before {
    content: '';
    position: absolute;
    width: calc(100vw - 40px);
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--text-disabled);
    z-index: -1;
}

.sectionButton p {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.arrow {
    transform: rotate(180deg);
    width: 16px;
    height: 8px;
}

.arrow path {
    stroke: var(--text-light-gray-secondary);
    stroke-opacity: 1;
}

@media (hover: hover) {
    .sectionButton:hover:not([disabled]):not(.pressed) {
        background: var(--white-hover);
        transition: all 0.2s;
    }
}
