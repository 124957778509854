.genderLabel {
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    min-width: 100px;
}

.horizontalContainer {
    display: flex;
    align-items: flex-end;
    gap: 6px;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.imageCont {
    width: 100%;
    height: 100px;
    border-radius: 20px;
    margin: 30px 0;
    position: relative;
    border: 1px solid transparent;
}

.imageCont img {
    width: 100%;
    height: 98px;
    object-fit: cover;
    border-radius: 20px;
}

.imageCont.placeholder {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    border: 1px dashed var(--text-light-gray-secondary);
}

.entityTitle,
.infoObj {
    background: var(--text-light-gray-secondary);
    color: var(--white);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 14px;
    backdrop-filter: blur(2px);
    z-index: 2;
}

.entityTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-height: 60%;
    max-width: 80%;
    padding: 8px 16px;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.infoCont {
    position: absolute;
    bottom: 3px;
    right: 6px;
    z-index: 3;
    display: flex;
    gap: 3px;
    max-width: 100%;
}

.changeCoverButton {
    position: absolute;
    bottom: 3px;
    left: 6px;
    z-index: 3;
    display: flex;
    gap: 3px;
    max-width: 100%;
    background: var(--text-light-gray-secondary);
    color: var(--white);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 14px;
    backdrop-filter: blur(2px);
    padding: 0 6px;
    cursor: pointer;
}

.changeCoverButton svg {
    transform: scale(0.75);
    filter: invert(1);
}

.infoObj {
    display: flex;
    gap: 3px;
    padding: 3px;
    align-items: center;
}

.icon svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
}

.friendly path {
    fill: var(--primary-green);
}

.neutral path {
    fill: var(--yellow);
}

.rude path {
    fill: var(--primary-red);
}

.error {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -150%);
    width: max-content;
    white-space: nowrap;
    color: var(--primary-red);
}
