.theme {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: start;
    justify-content: space-between;
    min-width: calc(25% - 9px);
    max-width: calc(25% - 9px);
    margin-right: 12px;
    max-height: 100%;
    padding: 10px 0;
    vertical-align: top;
    white-space: normal;
    scroll-margin: 8px;
    user-select: none;
    background: var(--light-gray);
    border: 1px solid var(--primary-light-gray);
    border-radius: 12px;
}

.theme.teacher {
    background: var(--teacher-theme-background);
    border: var(--teacher-theme-border);
}

.lessons {
    padding: 8px 16px;
    overflow: auto;
}

.lessons::-webkit-scrollbar {
    width: 0;
}

.lessons.locked {
    pointer-events: none;
}

.lessons > div:last-child > div {
    margin-bottom: 0;
}

.title,
.theme > input {
    padding: 0px 8px 0px 16px;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    background: transparent;
    border: transparent;
    margin-right: 30px;
    min-height: 32px;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.actionButton {
    position: absolute;
    right: 16px;
    top: 22px;
    display: flex;
    gap: 3px;
    padding: 4px;
    cursor: pointer;
}

.actionButton span {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--text-light-gray);
    transition: background-color 0.3s;
}

.actionsList {
    background: var(--white);
    border-radius: 8px;
    padding: 6px 0;
    position: absolute;
    max-width: 120px;
    box-shadow: 0px 4px 12px 0px var(--black-transparent);
    z-index: 3;
    right: 5px;
}

.actionsList p {
    padding: 6px 12px;
    font-size: 12px;
    line-height: 20px;
    height: 32px;
    max-height: 32px;
    transition: all 0.2s;
    cursor: pointer;
}

.actionsList p:last-child {
    color: var(--primary-red);
}

@media (hover: hover) {
    .actionsList p:hover {
        background: var(--secondary-light-gray);
    }

    .actionButton:hover span {
        background: var(--text-light-gray-secondary);
    }
}
