.wordsWrapper {
    gap: 12px;
    padding-bottom: 20px;
    max-width: 100%;
}

.wordsWrapper.modal {
    padding: 20px;
}

.wordsList {
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
}

.chaptersContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    position: relative;
}

.modesContainer {
    position: absolute;
    bottom: 20px;
    transform: translate(-50%, -100%);
    left: 50%;
    width: calc(100% - 20px);
    background: var(--primary-light-gray);
    padding: 4px;
    height: 42px;
    border-radius: 10px;
    box-shadow:
        0px 3px 1px 0px var(--black-transparent),
        0px 3px 8px 0px var(--black-transparent);
}

.modeToggle,
.modeToggle > * {
    font-size: 15px;
}
