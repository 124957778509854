.translationCont {
    padding: 12px 14px;
    padding-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    width: 100%;
    height: 100%;
    max-height: 15%;
    background: var(--black);
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    pointer-events: none;
    background: var(--modal-overlay-background);
}

.translationText {
    color: var(--white);
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.translationText span {
    background: var(--purple);
    color: var(--white);
    font-family: Noto Sans;
    padding: 3px 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}
