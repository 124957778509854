.chip {
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--secondary-light-gray);
    transition: all 0.2s;
    border-radius: 13px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    cursor: default;
    line-break: anywhere;
}
