.container {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    padding: 8px;
    margin: 0;
    background: var(--white);
    border: none;
    height: 32px;
    max-height: 32px;
    display: flex;
    align-items: center;
    outline: none;
    border-radius: 8px;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    position: relative;
    transition: border 0.1s;
    border: 0.6px solid transparent;
    transition: background-color 0.2s;
}

.outlined,
.errored {
    padding: 8px 16px;
    border: 0.3px solid var(--border-light-gray);
    height: 42px;
    max-height: 42px;
}

.outlinedSmall,
.erroredSmall {
    padding: 8px 16px;
    height: 32px;
    max-height: 32px;
    padding-left: 8px;
}

.outlinedSmall {
    border: 0.3px solid var(--border-light-gray);
}

.erroredSmall,
.errored {
    border: 1px solid var(--text-light-red);
}

.filled {
    background: var(--primary-light-gray);
}

.input::placeholder {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    color: var(--night-gray);
}

.icon {
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
}

.resetButton {
    width: 20px !important;
    position: absolute;
    right: 31.5px;
    top: 50%;
    transform: translateY(-50%);
}

@media (hover: hover) {
    .input.outlined:not(:focus):hover {
        background: var(--white-hover);
    }
}
