.container {
    display: block;
    position: relative;
    transition: all 0.2s;
    width: 100%;
    min-height: 600px;
    margin: 0 auto;
}

.container.portrait {
    aspect-ratio: 9 / 16;
}

.container.landscape {
    aspect-ratio: 16 / 9;
    height: min-content;
}

.frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
}

.frame.portrait {
    height: 100%;
}

.frame.landscape {
    height: 100%;
    filter: drop-shadow(
        10.132px 14.737px 36.842px var(--modal-overlay-background)
    );
}

.image,
.video,
.background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
}

.background {
    background: var(--black);
    z-index: -1;
}

.image.landscape {
    height: 85%;
}

.image.portrait {
    height: 95%;
}

.videoCont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
}

.video {
    height: 80%;
}
