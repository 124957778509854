.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
}

.wrapper iframe {
    width: 500%;
    height: 100%;
    margin-left: -200%;
}

.controls {
    position: absolute;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    transition: opacity 0.2s;
}

@media (hover: hover) {
    .wrapper:hover .controls {
        opacity: 1;
        pointer-events: all;
    }
}
