.modalInner {
    display: flex;
    max-width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: -8px;
    width: 100%;
}

.subtitle {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    margin-bottom: 12px;
    width: 100%;
}

.description {
    position: relative;
    left: 10px;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
    white-space: wrap;
}

.horizontalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.linkContainer {
    border: 0.3px solid var(--border-light-gray);
    padding: 10px 16px;
    border-radius: 12px;
    color: var(--black);
    cursor: pointer;
}

.linkLabel {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray);
}

.message {
    position: absolute;
    background-color: var(--modal-overlay-background);
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 35px));
    opacity: 0.8;
    animation: fadeOut 3s forwards;
    color: var(--white);
    padding: 6px 12px;
    border-radius: 8px;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.buttonContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 80px;
    padding: 12px 20px;
    cursor: pointer;
    /* TODO fix transition bug while onboarding @saratovkin */
    /* transition: background-color 0.2s; */
    background: var(--white);
}

.buttonContainer p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: var(--text-light-gray);
}

.buttonContainer > button {
    width: 44px;
    height: 44px;
    min-height: 44px !important;
    background: var(--secondary-light-gray);
}

@media (hover: hover) {
    .buttonContainer:hover {
        background: var(--white-hover);
    }

    .buttonContainer > button:hover {
        background: var(--secondary-light-gray) !important;
    }
}
