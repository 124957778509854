.situation {
    display: flex;
    flex-direction: column;
    gap: 0;
    overflow: visible hidden;
    padding-top: 14px;
    height: 100%;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    margin: 0 20px 20px;
}

.situationTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}

.infoBlock {
    width: calc(100% - 40px);
    margin: -10px 20px 20px;
    border-radius: 12px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    gap: 16px;
    border-radius: 12px;
    background: var(--primary-light-gray);
}

.optionsContainer {
    flex-grow: 1;
}

.optionsContainer:last-child {
    flex-grow: 0;
}

.buttonIcon {
    height: 12px;
    width: 20px;
    min-width: 20px;
    margin-left: -6px;
}

.buttonIcon path {
    stroke: var(--white);
}

.buttonIcon.dark path {
    stroke: var(--black);
}

.genderIcon {
    min-width: 16px;
    margin-left: -3px;
}

.genderIcon path {
    fill: var(--white);
}

.genderIcon.dark path {
    fill: var(--black);
}

.happy path {
    stroke: var(--black);
}

.neutral path {
    stroke: var(--black);
}

.sad path {
    stroke: var(--black);
}

.icons {
    display: flex;
    align-items: center;
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.icons svg:first-child path {
    fill: var(--black);
}

.icons svg:last-child path {
    stroke: var(--black);
}

.icons svg:first-child {
    width: 16px;
    height: 20px;
    margin-right: -3px;
    margin-left: 2px;
}

.icons svg:last-child {
    width: 16px;
    height: 12px;
    margin-left: -1px;
}

.icons.white svg:first-child path {
    fill: var(--white);
}

.icons.white svg:last-child path {
    stroke: var(--white);
}
