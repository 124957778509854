.confirmModal {
    display: flex;
    max-width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: -8px;
    width: 100%;
}

.subtitle {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    margin-bottom: 12px;
    width: 100%;
}

.buttons {
    width: 100%;
    margin-top: 20px;
    display: flex;
    gap: 12px;
}
