.searchContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    user-select: none;
    width: 100%;
    margin: 0 auto;
}

.plusIcon path {
    stroke-opacity: 1;
    stroke: var(--black);
}
