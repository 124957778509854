.generateModal {
    display: flex;
    max-width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
}

.generateModal > * {
    width: 100%;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: -8px;
}

.subtitle {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
    margin-bottom: 12px;
}

.error {
    color: var(--primary-red);
}
