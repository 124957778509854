.theoryCard {
    gap: 30px;
    padding: 60px 20px;
    overflow-y: scroll;
}

.mainInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mainInfo {
    gap: 16px;
    margin-bottom: 32px;
}

.wordText {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    margin-bottom: -8px;
    width: 100%;
    text-align: center;
    word-wrap: break-word;
}

.wordInfo {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: var(--text-light-gray-secondary);
}

.additionalInfo,
.infoCont {
    display: flex;
    flex-direction: column;
}

.additionalInfo {
    gap: 24px;
    width: 100%;
}

.infoCont {
    gap: 16px;
}

.partOfSpeech {
    color: var(--dictionary-accent-blue);
    font-size: 15px;
}

.infoLabel {
    padding: 3px 6px;
    border-radius: 6px;
    background: var(--primary-light-gray);
    color: var(--text-light-gray-secondary);
    width: fit-content;
    text-transform: uppercase;
}

.infoText,
.infoTranslation {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.infoTranslation {
    color: var(--text-light-gray-secondary);
}

.playButton {
    height: 56px;
    width: 56px;
    padding: 0;
    border-radius: 50%;
    position: relative;
}

.playButton svg {
    height: 16px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.playButton path {
    stroke: var(--black);
}
