.buttonContainer {
    width: 100%;
    min-height: 32px;
    position: relative;
    background: var(--purple);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.35s;
    position: relative;
    z-index: 4;
}

.buttonText {
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
    user-select: none;
}

.options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
    position: absolute;
    background: var(--white);
    bottom: 44px;
    z-index: 3;
    pointer-events: none;
    opacity: 0;
}

.shadow {
    position: fixed;
    border-radius: 12px;
    box-shadow: 0px 0px 8px 4px var(--black-transparent);
    pointer-events: none;
}

.buttonContainer.expanded .options {
    opacity: 1;
    pointer-events: all;
}

.optionContainer {
    width: 100%;
    height: 60px;
    border: 1px solid var(--dialogue-accent-gray);
    background: var(--white);
    border-radius: 8px;
    padding: 12px 14px;
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.1s;
}

.iconContainer {
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.optionContainer.exercise .iconContainer {
    background: var(--blue-transparent);
}

.optionContainer.tracks .iconContainer {
    background: var(--light-purple);
}

.optionContainer.situation .iconContainer {
    background: var(--orange-transparent);
}

.optionContainer.dictionary .iconContainer {
    background: var(--words-green-transparent-hover);
}

.title {
    font-size: 16px;
    line-height: 21.79px;
}

.subtitle {
    font-size: 12px;
    font-weight: 300;
    line-height: 16.34px;
    color: var(--text-light-gray-secondary);
}

.intro {
    padding: 20px;
    max-width: 640px;
    opacity: 0;
    transition: opacity 0.5s;
    animation: fadeIn 0.2s ease-in-out forwards;
    user-select: none;
    z-index: 12;
}

.modalTitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}

.modalSubtitle {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    margin-top: 12px;
    white-space: pre-line;
    text-align: justify;
}

.intro > button {
    width: 100%;
    margin-top: 24px;
    font-size: 15px;
    line-height: 18px;
    height: 38px;
    min-height: 38px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@media (hover: hover) {
    .optionContainer.exercise:not(.onboarding):hover {
        background: var(--blue-transparent);
    }
    .optionContainer.exercise:not(.onboarding):hover .iconContainer {
        background: var(--blue);
    }
    .optionContainer.track:not(.onboarding):hover {
        background: var(--light-purple);
    }
    .optionContainer.track:not(.onboarding):hover .iconContainer {
        background: var(--purple);
    }
    .optionContainer.situation:not(.onboarding):hover {
        background: var(--orange-transparent);
    }
    .optionContainer.situation:not(.onboarding):hover .iconContainer {
        background: var(--orange);
    }
    .optionContainer.words:not(.onboarding):hover {
        background: var(--words-green-transparent-hover);
    }
    .optionContainer.words:not(.onboarding):hover .iconContainer {
        background: var(--words-green);
    }
    .optionContainer:not(.onboarding):hover .iconContainer path,
    .optionContainer:not(.onboarding):hover .iconContainer rect {
        filter: brightness(0) invert(1);
    }
}
