.tooltip {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-wrap: nowrap;
    width: min-content;
    top: 0px;
    left: 0px;
    padding: 10px 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    pointer-events: none;
    animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    backdrop-filter: blur(1px);
}

.tooltip::before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0%;
    transform: translateX(20px) rotate(45deg);
    width: 8px;
    height: 8px;
    box-shadow: 1px 2px 4px 0px var(--black-transparent);
}

.tooltip span {
    font-size: 12px;
    white-space: nowrap;
}

.tooltip.center:before {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.tooltip.warning {
    background: var(--warning-yellow);
}

.tooltip.warning::before {
    background: var(--warning-yellow);
}

.tooltip.warning span {
    color: var(--accent-orange);
}

.warningIcon {
    min-width: 14px;
}

.warningIcon path {
    fill: var(--accent-orange);
}

.tooltip.error {
    background: var(--primary-transparent-red);
}

.tooltip.error::before {
    background: var(--primary-transparent-red);
}

.tooltip.error span {
    color: var(--primary-red);
}

.tooltip.default {
    background: var(--black-hover);
    color: var(--white);
}

.tooltip.default::before {
    background: var(--black-hover);
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-0.5px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(0.5px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-1px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(1px, 0, 0);
    }
}
