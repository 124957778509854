.intro {
    padding: 20px;
    max-width: 640px;
    opacity: 0;
    transition: opacity 0.5s;
    animation: fadeIn 0.2s ease-in-out forwards;
    user-select: none;
}

.title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
}

.subtitle {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    margin-top: 12px;
    white-space: pre-line;
    text-align: justify;
}

.exmapleButtonWrapper {
    display: inline;
}

.exmapleButtonWrapper > button {
    display: inline;
    color: var(--purple) !important;
    width: fit-content;
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    height: 26px;
    padding: 0px 4px;
}

.intro > button {
    width: 100%;
    margin-top: 24px;
    font-size: 15px;
    line-height: 18px;
    height: 38px;
    min-height: 38px;
}

.videoContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90vh;
    max-height: 90vh;
    width: 42.45vh;
    padding: 0;
    overflow: hidden;
    background: var(--black);
    border-radius: 16px;
}

.videoContainer video {
    outline: none;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
