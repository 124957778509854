.generateText {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.subtitle {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: var(--text-light-gray-secondary);
}

.horizontalContainer {
    display: flex;
    gap: 25px;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;
}

.modeButtonsContainer {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.buttonContainer {
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 1px 4px 0px var(--black-transparent);
    border-radius: 12px;
    height: 260px;
    width: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    min-width: 14px;
}

.icon path {
    stroke: var(--white);
}

.text {
    width: 100%;
    font-weight: 400;
    padding: 6px 32px 8px 8px;
    margin: 0;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 12px;
    box-sizing: border-box;
    min-height: 250px;
    font-size: 12px;
    line-height: 16px;
    resize: vertical;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    outline: none;
    overflow-y: scroll;
    height: max-content;
    max-height: 50vh;
    transition: background-color 0.1s;
    resize: none;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    margin-top: 10px;
}

.inputContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
