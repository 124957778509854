.exerciseOptions {
    display: flex;
    width: 100%;
    max-width: 800px;
    margin: auto;
    flex-direction: column;
    gap: 25px;
    overflow: scroll;
}

.optionsContainer {
    display: flex;
    gap: 10px;
    align-items: end;
    position: relative;
}

.optionsContainer:nth-child(1) {
    align-items: center;
    justify-content: space-between;
}

.optionsContainer:nth-child(4) {
    justify-content: space-between;
}

.paramsContainer {
    display: flex;
    gap: 25px;
}

.toggleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.errorMessage {
    line-height: 32px;
    font-size: 12px;
    color: var(--primary-red);
}

.preview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid var(--primary-light-gray);
    padding: 6px 12px;
    border-radius: 12px;
}

.preview p {
    left: 0;
}
