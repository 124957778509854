.modal {
    padding: 20px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    width: 97vw;
    height: 97vh;
    max-height: 97vh;
    max-width: 97vw;
    box-sizing: border-box;
    overflow: hidden;
    left: 1.5vw;
    top: 1.5vh;
    position: absolute;
    background: var(--white);
    border-radius: 24px;
    z-index: 10;
}

.modalHeader {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    min-height: 33px;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
}

.titleWithIcon {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 18px;
    font-weight: 500;
}

.titleWithIcon svg {
    width: 24px;
    height: 24px;
}
