.preview {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    gap: 0;
    height: 100%;
}

.container {
    padding: 20px 20px 50px;
    overflow: scroll;
    scroll-behavior: smooth;
}

.exerciseTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}

.exerciseTitle span {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-light-gray);
    margin-left: 10px;
}

.exerciseDescription,
.textAlert {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    margin-top: 7px;
    margin-bottom: 24px;
    color: var(--text-light-gray-secondary);
}

.buttonsContainer {
    background: var(--white);
    padding: 5px;
    margin: auto 10px 10px;
    z-index: 1;
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 1px 4px 0px var(--black-transparent);
    border-radius: 12px;
}

.previewButtons {
    background: var(--white);
    padding: 5px;
    margin: 0 10px;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.info {
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: fit-content;
    align-items: end;
}

.infoText {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
    white-space: nowrap;
}

.infoText span {
    font-size: 12px;
    color: var(--text-light-gray-secondary);
}

.previewContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;
}

.hideYT {
    visibility: hidden;
    height: 0;
}

.exerciseText {
    font-style: italic;
    white-space: pre-line;
}

.videoContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.textAlert {
    text-align: right;
    margin-bottom: -20px;
}

.doneText {
    line-height: 20px;
    font-size: 14px;
    margin: 6px auto;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.doneText.unfinished {
    color: var(--purple);
    white-space: pre-wrap;
}

.doneText.done {
    color: var(--words-green);
}
