.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: var(--modal-overlay-background);
    z-index: 5;
    white-space: pre;
}

.modal.blur {
    background-color: var(--modal-overlay-background);
    backdrop-filter: blur(0.5px);
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
    transition: opacity 0.5s;
}

.modal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalTitle {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
    color: var(--white);
    white-space: pre;
}

.modalContent {
    padding: 25px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-height: 75vh;
    overflow: auto;
    box-shadow: 3px 3px 30px var(--black-transparent-secondary);
    max-width: 1090px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
