.horizontalContainer {
    display: flex;
    align-items: center;
    gap: 25px;
}

.error {
    width: max-content;
    white-space: nowrap;
    color: var(--primary-red);
    font-size: 12px;
}
