.editModal {
    padding: 24px 0 12px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    align-items: center;
    min-width: 800px;
    max-width: 80vw;
    min-height: 50vh;
    max-height: 95vh;
    height: fit-content;
    box-sizing: border-box;
    position: absolute;
    background: var(--white);
    border-radius: 24px;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    padding: 0 24px;
}

.editModal > * {
    width: 100%;
}

.buttonsContainer {
    margin-top: auto;
    background: var(--white);
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 12px 24px 0px;
}

.editorContainer {
    flex: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 10px 24px;
    gap: 10px;
}

.horizontalCont {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
}
