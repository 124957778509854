.primarySelect {
    position: relative;
    padding: 0px 15px;
    padding-right: 47px;
    height: 32px;
    display: flex;
    color: var(--text-light-gray-secondary);
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 400;
    cursor: pointer;
    outline: none;
    width: fit-content;
    align-items: center;
    transition: background-color 0.2s;
    width: 150px;
}

.primarySelect.white {
    background: var(--white);
}

.primarySelect.outlined {
    background: var(--white);
    height: 42px;
    border: 0.3px solid var(--border-light-gray);
}

.primarySelect.grey {
    background: var(--primary-light-gray);
}

.primarySelect.narrow {
    padding: 0 24px 0 6px;
}

.primarySelect .resetButton {
    width: 20px;
    position: relative;
    left: 16px;
}

.primarySelect.narrow .resetButton,
.primarySelect.narrow .resetButton button {
    width: 12px;
    height: 12px;
    border-radius: 3px;
}

.primarySelect.narrow .resetButton button {
    position: relative;
    left: -12px;
}

.primarySelect > * {
    width: 100%;
    user-select: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
}

.primarySelect.disabled {
    cursor: not-allowed;
}

.arrowDown {
    content: url('/src/assets/svg/lessons-arrow.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: rotate(180deg) translateY(50%);
    right: 13px;
}

.arrowUp {
    content: url('/src/assets/svg/lessons-arrow.svg');
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
}

.primarySelect.narrow .arrowDown,
.primarySelect.narrow .arrowUp {
    right: 6px;
}

.options {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: max-content;
    max-height: 400px;
    overflow: hidden scroll;
    color: var(--text-light-gray-secondary);
    background: var(--white);
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 8px;
    padding: 5px 0;
    box-shadow: 0px 2px 12px 0px var(--black-transparent);
}

.options.fixed {
    position: fixed;
}

.options.down {
    top: calc(100% + 5px);
    border-top: none;
}

.options.up {
    bottom: calc(100% + 5px);
    border-bottom: none;
}

.options::-webkit-scrollbar {
    width: 0;
}

.optionCont {
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    padding: 0 6px;
    margin: 0 auto;
    border-radius: 8px;
    border: none;
    min-height: 35px;
    cursor: pointer;
    position: relative;
}

.option {
    padding-right: 0px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.option.active {
    font-weight: 700;
}

.value {
    padding-right: 0px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-light-gray-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
}

.value.active {
    color: var(--black);
}

.primarySelect.disabled .value {
    color: var(--text-light-gray);
}

@media (hover: hover) {
    .optionCont:hover:not(.disabled) {
        background: var(--primary-light-gray);
    }

    .primarySelect.white:hover:not(.expanded):not(.disabled) {
        background: var(--white-hover);
    }

    .primarySelect.outlined:hover:not(.expanded):not(.disabled) {
        background: var(--white-hover);
    }

    .primarySelect.grey:hover:not(.expanded):not(.disabled) {
        background: var(--primary-light-gray-hover);
    }
}
