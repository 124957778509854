.firstSection {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    height: 85vh;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    user-select: none;
}

.sectionInner {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.firstSection .sectionInner .mainTitle {
    max-width: 70%;
    font-family: Taler !important;
}

.gradesImg {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    z-index: -1;
    height: 60vh;
    max-height: 900px;
    min-height: 600px;
}

.gradesImg:first-child {
    left: -20px;
}

.gradesImg:last-child {
    right: -20px;
}

.videoButton {
    background: var(--light-gray);
    padding: 5px;
    padding-right: 30px;
    border: 1px solid var(--primary-light-gray);
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 15px;
    width: fit-content;
    margin: 0 auto;
    position: relative;
}

.videoButton img {
    width: 110px;
    height: 62px;
    background: var(--night-gray);
    cursor: pointer;
    border-radius: 12px;
}

.videoButton::before {
    content: '';
    position: absolute;
    width: calc(100vw - 40px);
    height: 1px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--text-disabled);
    z-index: -1;
}

.buttonText p {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: var(--black);
}

.buttonText p:last-child {
    color: var(--text-light-gray-secondary);
}
