.learnWords {
    width: 100%;
    font-size: 15px;
}

.wordsModal {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    border-radius: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--primary-light-gray);
    z-index: 14;
}

.wordsModal::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
}

.wordsModal.continue::before {
    background: var(--green-transparent);
}

.wordsModal.repeat::before {
    background: var(--primary-transparent-red);
}

.settingsButton {
    position: absolute;
    height: 22px;
    top: 16px;
    right: 30px;
    transform: translateX(-100%);
    cursor: pointer;
}

.settingsModal {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 25px;
    max-height: 95vh;
    overflow: scroll;
}

.settingsTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: -8px;
}

.settingsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px 16px;
}

.switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-light-gray);
    border-radius: 12px;
    height: 60px;
    padding: 16px;
    box-sizing: border-box;
    min-width: 25vw;
}

.label,
.toggleLabel {
    font-size: 14px;
    color: var(--black);
    text-align: left;
    width: 100%;
}

.toggleLabel {
    margin-bottom: -20px;
}

.packSizeSelector {
    width: 100%;
    padding: 4px;
    height: 42px;
    border-radius: 10px;
    background: var(--primary-light-gray);
}
