.wordCard {
    display: flex;
    width: calc(50% - 20px);
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 2px 7px 0px var(--black-transparent);
    height: fit-content;
    min-height: 400px;
    max-height: calc(100% - 84px);
    position: absolute;
    right: 20px;
    top: 60px;
    background: var(--white);
    overflow: scroll;
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
    filter: grayscale(1);
    opacity: 0.5;
    transition: opacity 0.2s;
    cursor: pointer;
}

.wordText {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.wordText span {
    width: 100%;
    font-size: 30px;
    font-weight: 500;
    line-height: 41px;
    text-align: center;
}

.errorStatus {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: var(--primary-red);
}

.partOfSpeech {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: var(--dictionary-accent-blue);
}

.card {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card > div {
    margin-bottom: 16px;
}

.wordInfo {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.infoLabel {
    padding: 3px 6px;
    margin-bottom: 6px;
    background: var(--transparent-gray);
    border-radius: 6px;
    color: var(--text-light-gray-secondary);
    width: fit-content;
    text-transform: uppercase;
}

.translation {
    color: var(--text-light-gray-secondary);
    margin-bottom: 6px;
}

.emptyAlert {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 50%;
    position: relative;
}

.infoText,
.exampleText {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    width: fit-content;
    white-space: wrap;
}

.exampleText {
    margin-bottom: 6px;
}

.exampleText:last-child {
    margin-bottom: 30px;
}

.exampleText button,
.wordText button {
    display: inline-flex;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin-right: 6px;
    margin-top: 3px;
    padding: unset;
}

.wordText button {
    position: absolute;
    left: 20px;
    top: 20px;
    margin: 0;
}

.soundIcon path {
    stroke: var(--black);
}

.wordCard svg {
    width: 14px;
    height: 12px;
}

.buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.buttons button:last-child {
    margin-left: auto;
}

.horizontalCont {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.wordLoader {
    opacity: 0.5;
    max-height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
}

@media (hover: hover) {
    .close:hover {
        opacity: 0.9;
    }
}
