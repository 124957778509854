.playerContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden scroll;
    padding: 20px;
}

.playerContainer > * {
    max-width: 100%;
}

.playerContainer.ponyPlayer > * {
    max-width: 50%;
    width: 50%;
}

.ponyPlayer {
    flex-direction: row;
    padding: 20px;
}

.title {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    min-height: 22px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.title.animated {
    opacity: 0;
    transition: opacity 1s;
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.5s;
}

.trackContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
}

.trackContainer.animated {
    margin-top: -75%;
    transition: all 1s;
    animation: scrollDown 0.5s forwards;
    animation-delay: 0.5s;
}

.ponyPlayer .trackContainer {
    margin: 0 auto;
}

.transcriptionTitle {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.transcriptionTitle p {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--text-light-gray-secondary);
    position: relative;
    text-align: center;
}

.line {
    height: 1px;
    background: var(--text-light-gray-secondary);
    flex-grow: 1;
}

.textContainer {
    z-index: 1;
    background: var(--white);
}

.ponyPlayer .textContainer {
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
}

.trackErrorAlert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.trackErrorAlert p {
    font-size: 12px;
    color: var(--text-light-gray-secondary);
}

@keyframes scrollDown {
    from {
        margin-top: -75%;
    }
    to {
        margin-top: 0;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
